import { useCallback } from 'react';
import {
  Text, Box, Divider, Stack, Group, Loader, ActionIcon
} from '@mantine/core';

import type {
  OrganizationResource, WithoutPermissions
} from '../../types/apiTypes';
import useEnsisQuery from '../../hooks/useEnsisQuery';
import { DownloadProposal } from '../../icons';

interface ResourceHeaderProps {
  orgResource: WithoutPermissions<OrganizationResource>
}

const ResourceHeader: React.FC<ResourceHeaderProps> = ({ orgResource }: ResourceHeaderProps) => {
  const { data, isLoading } = useEnsisQuery(
    `/app/organization-resources/${orgResource?.uid}/data`,
    { queryParams: { generate_download_url: true } }
  );

  const onClickDownload = useCallback(() => {
    window.open(data?.url ?? '', '_blank', 'noreferrer');
  }, [data?.url]);

  const downloadIcon = isLoading
    ? <Loader size="sm" />
    : (
      <ActionIcon
        size={28}
        variant='subtle'
        onClick={onClickDownload}
      >
        <DownloadProposal color='var(--mantine-color-gray-6)' />
      </ActionIcon>
      );

  return (
    <Box mb={16}>
      <Stack>
        <Group justify='space-between'>
          <Text fw={600}>{orgResource.name}</Text>
          {downloadIcon}
        </Group>
      </Stack>
      <Divider mt={8} size='sm' color='var(--mantine-color-gray-2)' />
    </Box>
  );
};

export default ResourceHeader;
