import { useCallback, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

import type { RouteWithGet, GetRequestQueryParamsType, GetResponseDataType } from '../types/apiTypes';
import { getAuthHeaders, getFullURLForRoute } from '../utils/apiUtils';
import { showFailureNotification, showSuccessNotification } from '../utils/mantineUtils';

export interface QueryConfig<Route extends RouteWithGet> {
  showSuccessMessage?: boolean
  successMessage?: string
  showFailureMessage?: boolean
  failureMessage?: string
  queryParams?: GetRequestQueryParamsType<Route>
  queryKey?: any[]
  onSuccess?: (data: GetResponseDataType<Route>) => void
  enabled?: boolean
  refetchInterval?: number
  staleTime?: number
  gcTime?: number
};

const defaultQueryConfig = {
  showSuccessMessage: false,
  showFailureMessage: true
};

function useEnsisQuery <Route extends RouteWithGet> (
  route: Route,
  queryConfig?: QueryConfig<Route>
) {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const queryConfigWithDefaults = {
    ...defaultQueryConfig,
    ...(queryConfig ?? {})
  };
  const {
    showSuccessMessage,
    showFailureMessage,
    successMessage,
    failureMessage,
    queryParams,
    queryKey,
    onSuccess,
    enabled,
    refetchInterval,
    gcTime,
    staleTime
  } = queryConfigWithDefaults;

  const queryFunction = useCallback(async (): Promise<GetResponseDataType<Route>> => {
    // Get Auth0 access token
    const accessToken = await getAccessTokenSilently();

    // Set auth header and get result
    const result = await axios.get(
      getFullURLForRoute(route),
      {
        headers: getAuthHeaders(accessToken),
        params: queryParams ?? {}
      }
    );
    return result.data;
  }, [getAccessTokenSilently, route, queryParams]);

  const shouldRunQuery = (isAuthenticated && user?.email_verified) ?? false;

  const defaultQueryKey: any[] = [route];
  if (queryParams !== undefined) {
    defaultQueryKey.push(queryParams);
  }

  const useQueryResult = useQuery({
    queryKey: queryKey ?? defaultQueryKey,
    queryFn: queryFunction,
    enabled: shouldRunQuery && (enabled ?? true),
    gcTime,
    refetchInterval,
    staleTime
  });
  const { isSuccess, isLoading, isError, data, error } = useQueryResult;

  useEffect(() => {
    const queryIsFailure = !isLoading && isError;
    if (queryIsFailure) {
      if (showFailureMessage ?? false) {
        showFailureNotification(failureMessage);
      }
      Sentry.captureException(error);
    }
  }, [
    isLoading,
    isError
  ]);
  useEffect(() => {
    if (isSuccess) {
      if (onSuccess !== undefined) {
        onSuccess(data);
      }
      if (showSuccessMessage ?? false) {
        showSuccessNotification(successMessage);
      }
    }
  }, [isSuccess]);
  return useQueryResult;
};

export default useEnsisQuery;
