import { useCallback } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import type { AppState } from '@auth0/auth0-react';
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { useNavigate, Outlet } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Notifications } from '@mantine/notifications';
import { Worker } from '@react-pdf-viewer/core';

import store from './redux/store';
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_API_AUDIENCE_NAME } from './Config';

// Default stale time = 5 minutes
const DEFAULT_STALE_TIME_MS = 1000 * 60 * 5;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: DEFAULT_STALE_TIME_MS
    }
  }
});

const BaseApp = () => {
  const navigate = useNavigate();

  const onRedirectCallback = useCallback((appState?: AppState, user?: any) => {
    navigate(
      appState?.returnTo != null
        ? appState.returnTo
        : window.location.href
    );
  }, [navigate]);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Provider store={store}>
        <Auth0Provider
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: `${AUTH0_API_AUDIENCE_NAME}/`
          }}
          onRedirectCallback={onRedirectCallback}
        >
          <QueryClientProvider client={queryClient}>
            <Notifications position={'top-center'} w={350} />
            <Outlet />
          </QueryClientProvider>
        </Auth0Provider>
      </Provider>
    </Worker>
  );
};

export default BaseApp;
