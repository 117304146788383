import { Modal } from '@mantine/core';

import { RichTextEditor, Link } from '@mantine/tiptap';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import CharacterCount from '@tiptap/extension-character-count';

import { useEditor } from '@tiptap/react';
import { useEffect, useState, memo, useCallback } from 'react';

import { useDisclosure } from '@mantine/hooks';

import { useSelector } from 'react-redux';

import classes from './ResponseEditor.module.css';

import { RegenerateText } from '../Modals';
import ResponseEditorPlaceholder from './ResponseEditorPlaceholder';
import ResponseEditorToolbar from './ResponseEditorToolbar';
import { defaultModalProps, showWarningNotification } from '../../utils/mantineUtils';
import { CompliantHighlight } from '../../prosemirror/CompliantHighlighting';
import { selectNarrativeCompliance } from '../../redux/store';
import { getBasicWordCount, formatTextForEditor } from '../../utils/stringUtils';

const REGENERATE_MIN_WORDS = 4;

export interface ResponseTextEditorProps {
  contentStyleName: string
  onEditorCreate?: (text: string, wordCount: number) => void
  onEditorUpdate?: (newText: string, wordCount: number) => void
  onGenerateText?: () => void
  onShowSources?: () => void
  proposalSectionUid: string
  canEdit: boolean
  requirementResponseUid?: string
  initialContent?: string
  shouldShowWarning?: boolean
  setShouldShowWarningFalse?: () => void
  checkCompliance?: boolean
  alertText?: string
}

const ResponseTextEditor: React.FC<ResponseTextEditorProps> = (props: ResponseTextEditorProps) => {
  const {
    requirementResponseUid,
    initialContent,
    proposalSectionUid,
    contentStyleName,
    canEdit,
    onEditorUpdate,
    onEditorCreate,
    onGenerateText,
    onShowSources,
    shouldShowWarning,
    setShouldShowWarningFalse,
    alertText,
    checkCompliance = false
  } = props;

  const compliantExtension = checkCompliance ? [CompliantHighlight] : [];
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      Subscript,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      CharacterCount.configure({})
    ].concat(compliantExtension),
    content: formatTextForEditor(initialContent ?? ''),
    editable: canEdit,
    editorProps: {
      attributes: {
        style: 'padding: 12px 16px'
      }
    }
  }, [initialContent]);

  useEffect(() => {
    if ((editor?.isFocused ?? false) && (shouldShowWarning ?? false)) {
      showWarningNotification(
        `Caution: Outline changes aren't automatically added to your narrative.
         Save changes and then regenerate your narrative to include them.`
      );
      if (setShouldShowWarningFalse !== undefined) {
        setShouldShowWarningFalse();
      }
    }
  }, [editor?.isFocused, shouldShowWarning]);

  // false is the second param to not call "onUpdate" when setEditable is called
  useEffect(() => {
    editor?.setEditable(canEdit, false);
  }, [canEdit]);

  editor?.on('create', ({ editor }) => {
    if (onEditorCreate !== undefined) {
      onEditorCreate(editor.getHTML(), editor.storage.characterCount.words() as number);
    }
  });

  editor?.on('update', ({ editor }) => {
    if (onEditorUpdate !== undefined) {
      onEditorUpdate(editor.getHTML(), editor.storage.characterCount.words() as number);
    }
  });

  editor?.on('blur', () => {
    setRenderEditor(false);
  });
  const narrativeComplianceState = useSelector(selectNarrativeCompliance);

  useEffect(() => {
    if (editor !== null) {
      if (narrativeComplianceState.selectedResponseUid !== '') {
        editor?.view.focus();
      } else {
        editor.commands.blur();
      }
    }
  }, [narrativeComplianceState.selectedResponseUid]);

  useEffect(() => {
    if (alertText !== undefined) {
      setRenderEditor(false);
    }
  }, [alertText]);

  const [toolbarItemClicked, setToolbarItemClicked] = useState(false);
  const [modalOpened, modalHandlers] = useDisclosure();

  const showToolbar = editor !== null && editor?.isFocused ? editor?.isFocused : toolbarItemClicked;
  const [renderEditor, setRenderEditor] = useState(false);

  const shouldShowPlaceholder = (editor?.isEmpty === true && !renderEditor && canEdit) ||
    initialContent === undefined || initialContent == null;

  const handleModalClose = useCallback(() => {
    setToolbarItemClicked(false);
    modalHandlers.close();
  }, []);

  const handleOpenRegenerateModal = useCallback(() => {
    setToolbarItemClicked(true);
    modalHandlers.open();
    setRenderEditor(true);
  }, []);

  const insertRegeneratedText = useCallback((generatedText: string) => {
    editor?.chain().insertContent(generatedText).focus().run();
  }, [editor]);

  const handleClickPlaceholder = useCallback(() => {
    setRenderEditor(true);
    editor?.commands.focus();
  }, [editor?.commands.focus]);

  const state = editor?.state;
  const selection = state?.selection;
  let selectedText: string = '';
  if (selection !== undefined) {
    const { from, to } = selection;
    selectedText = state?.doc.textBetween(from, to, ' ') ?? '';
  }

  const regenerateDisabled = getBasicWordCount(selectedText) < REGENERATE_MIN_WORDS;
  const showSourcesDisabled = editor?.isEmpty;

  return (
    <RichTextEditor
      classNames={{
        linkEditor: classes.linkEditor,
        control: classes.control,
        root: classes.root,
        controlsGroup: classes.controlsGroup,
        content: contentStyleName,
        toolbar: classes.toolbar
      }}
      editor={editor}
    >
      <Modal opened={modalOpened} {...defaultModalProps} size={1000}>
        <RegenerateText
          selectedText={selectedText}
          onAccept={insertRegeneratedText}
          proposalSectionUid={proposalSectionUid}
          requirementResponseUid={requirementResponseUid}
          onClose={handleModalClose}
        />
      </Modal>
      {shouldShowPlaceholder
        ? (
          <ResponseEditorPlaceholder
            onGenerateContent={onGenerateText}
            onClick={handleClickPlaceholder}
            alertText={alertText}
          />
          )
        : <>
          {canEdit && showToolbar &&
            <ResponseEditorToolbar
              onClickGenerate={onGenerateText}
              onClickRegenerate={handleOpenRegenerateModal}
              regenerateDisabled={regenerateDisabled}
              onShowSources={onShowSources}
              showSourcesDisabled={showSourcesDisabled}
            />
          }
          <RichTextEditor.Content
            style={{
              overflow: 'auto',
              cursor: canEdit
                ? 'auto'
                : 'not-allowed',
              borderLeft: (editor?.isFocused !== undefined && editor?.isFocused)
                ? '3px solid var(--mantine-color-lightPurple-5)'
                : '',
              fontSize: '14px'
            }}
          />
        </>
      }
    </RichTextEditor>
  );
};

export default memo(
  ResponseTextEditor,
  (prevProps: ResponseTextEditorProps, nextProps: ResponseTextEditorProps) => (
    prevProps.canEdit === nextProps.canEdit &&
    prevProps.shouldShowWarning === nextProps.shouldShowWarning &&
    prevProps.requirementResponseUid === nextProps.requirementResponseUid &&
    prevProps.initialContent === nextProps.initialContent &&
    prevProps.onGenerateText === nextProps.onGenerateText &&
    prevProps.alertText === nextProps.alertText
  )
);
