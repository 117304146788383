import { type ComboboxData, Group, Select, Tooltip } from '@mantine/core';

export type EditorViewType = 'outline' |
'narrative' | 'documents' | 'compliance' | 'assistant';
export type Position = 'left' | 'right';

export interface ViewSelectorProps {
  isFullScreen?: boolean
  position: Position
  viewTypes: EditorViewType[]
  handleOptionSubmit: (value: string, position: Position) => void
  enabledViewTypes: EditorViewType[]
  currentViewType: EditorViewType
  rightSection?: React.ReactNode
};

export const viewTypeToLabelMap: Record<EditorViewType, string> = {
  outline: 'Outline View',
  narrative: 'Narrative View',
  documents: 'Documents',
  compliance: 'Compliance View',
  assistant: 'Resource Assistant'
};

const EditorPaneViewSelector: React.FC<ViewSelectorProps> = (props: ViewSelectorProps) => {
  const {
    position,
    viewTypes,
    isFullScreen,
    currentViewType,
    handleOptionSubmit,
    enabledViewTypes,
    rightSection
  } = props;

  const viewSelectorData: ComboboxData = viewTypes.map((viewType) => ({
    value: viewType,
    label: viewTypeToLabelMap[viewType],
    disabled: !enabledViewTypes.includes(viewType)
  }));

  return (
    <Group
      h={64}
      p="5 16 5 16"
      bg="var(--mantine-color-greyPurple-0)"
      style={{
        borderTopRightRadius: (position === 'right' || isFullScreen === true) ? 8 : 0,
        borderTopLeftRadius: position === 'left' ? 8 : 0,
        border: '1px var(--mantine-color-gray-2) solid',
        borderLeft: (position === 'right') ? '' : '1px var(--mantine-color-gray-2) solid',
        borderRight: (position === 'left' && isFullScreen !== true) ? '' : '1px var(--mantine-color-gray-2) solid'
      }}
      justify='space-between'
    >
      <Tooltip
        events={{
          hover: enabledViewTypes.length === 0,
          focus: false,
          touch: false
        }}
        label='Save changes before switching views.'
      >
        <Select
          disabled={enabledViewTypes.length === 0}
          comboboxProps={{ offset: 0 }}
          allowDeselect={false}
          value={currentViewType}
          w={177}
          styles={{
            input: {
              border: '1px var(--mantine-color-gray-3) solid',
              borderRadius: 4,
              opacity: 1,
              backgroundColor: 'white',
              color: enabledViewTypes.length === 0 ? 'var(--mantine-color-gray-5)' : 'var(--mantine-color-darkPurple-9)'
            },
            dropdown: {
              borderRadius: 4
            }
          }}
          scrollAreaProps={{ type: 'hover' }}
          data={viewSelectorData}
          onOptionSubmit={(value: string) => { handleOptionSubmit(value, position); }}
        />
      </Tooltip>
      {rightSection}
    </Group>
  );
};

export default EditorPaneViewSelector;
