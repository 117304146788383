import { Box, type BoxComponentProps, Center, Divider, Group, Text, Tooltip } from '@mantine/core';

import { useMemo } from 'react';

import { HelpCircle } from '../../icons';
import ResourceHeader from './ResourceHeader';
import {
  type MemoryItem,
  type OrganizationResource,
  type SourceAnalysis,
  type SourceItem,
  type WithoutPermissions
} from '../../types/apiTypes';

interface Props {
  showLastFactCheckTime: boolean
  sourceAnalysis?: WithoutPermissions<SourceAnalysis> | null
}

interface ResourceInfo {
  resource: WithoutPermissions<OrganizationResource>
  sources: SourceItem[]
}

interface GroupedSources {
  memoryItems: Array<WithoutPermissions<MemoryItem>>
  organizationResources: Record<string, ResourceInfo>
  proposalContextSources: string[]
}
const PROPOSAL_CONTEXT_TOOLTIP_TEXT = 'Win Themes / Differentiators are proposal-specific strategy details ' +
  'you provide. You can access them anytime under \'Proposal Management.\'';

const FACTS_TOOLTIP_TEXT = 'Fact management will store information to apply for future updates to this proposal or ' +
  'all future proposals. You can access these facts under \'Proposal Management\'. You can access \'global\' facts ' +
  'from User Settings.';
const sortByPageComparisonFn = (sourceA: SourceItem, sourceB: SourceItem): number => (
  (sourceA.page ?? 0) - (sourceB.page ?? 0)
);

const groupSources = (sources: SourceItem[]): GroupedSources => {
  const orgResourceSources: Record<string, ResourceInfo> = {};
  const memoryItemSources: Array<WithoutPermissions<MemoryItem>> = [];
  const proposalContextSources: string[] = [];
  sources.forEach((source) => {
    if (
      source.type === 'organization_resource' &&
      source.organization_resource !== undefined &&
      source.organization_resource != null
    ) {
      const resourceUid = source.organization_resource?.uid ?? '';
      if (resourceUid in orgResourceSources) {
        orgResourceSources[resourceUid].sources.push(source);
      } else {
        orgResourceSources[resourceUid] = {
          sources: [source],
          resource: source.organization_resource
        };
        orgResourceSources[resourceUid].sources = [source];
      }
    } else if (
      source.type === 'memory_item' &&
      source.memory_item !== undefined &&
      source.memory_item != null
    ) {
      memoryItemSources.push(source.memory_item);
    } else if (source.type === 'proposal_context') {
      proposalContextSources.push(source.text);
    }
  });
  return {
    memoryItems: memoryItemSources,
    organizationResources: orgResourceSources,
    proposalContextSources
  };
};
type CategoryHeaderProps = {
  headerText: string
  tooltipText: string
} & BoxComponentProps;

const CategoryHeader: React.FC<CategoryHeaderProps> = (
  { headerText, tooltipText, ...boxProps }: CategoryHeaderProps
) => (
  <Box {...boxProps}>
    <Group gap='xs'>
      <Text fw='bold' size='sm' style={{ margin: '0 0 0 0' }}>{headerText}</Text>
      <Tooltip
        label={tooltipText}
        events={{ hover: true, focus: false, touch: false }}
        w={590}
        multiline
      >
        <Center style={{ cursor: 'pointer' }}>
          <HelpCircle />
        </Center>
      </Tooltip>
    </Group>
    <Divider mt={8} size='sm' color='var(--mantine-color-gray-2)' />
  </Box>
);

const SourcesView: React.FC<Props> = (props: Props) => {
  const { showLastFactCheckTime, sourceAnalysis } = props;

  const groupedSources = useMemo(
    () => groupSources(sourceAnalysis?.source_items ?? []),
    [sourceAnalysis?.source_items]
  );

  const lastFactCheckTime = new Date(sourceAnalysis?.created_at ?? '');

  let lastFactCheckTimeString = lastFactCheckTime?.toLocaleTimeString();
  if (lastFactCheckTime?.toLocaleDateString() !== new Date().toLocaleDateString()) {
    lastFactCheckTimeString = lastFactCheckTime?.toLocaleDateString();
  }

  const documentSourcesSection = (
    <>
      {
        Object.values(groupedSources.organizationResources).map((resourceInfo) => (
          <Box key={resourceInfo.resource.uid} mb={16}>
            <ResourceHeader orgResource={resourceInfo.resource} />
            {
              resourceInfo.sources.toSorted(sortByPageComparisonFn).map((source) => {
                const hasPage = source.page !== undefined && source.page != null;
                const hasRow = source.row !== undefined && source.row != null;
                return (
                  <Box key={source.text} mb={16}>
                    <Text fz='sm'>{`"${source.text}"`}</Text>
                    {hasPage && <Text fw={600} c='var(--mantine-color-gray-5)'>{`pg ${source.page}`}</Text>}
                    {hasRow && <Text fw={600} c='var(--mantine-color-gray-5)'>{`row ${source.row}`}</Text>}
                  </Box>
                );
              })
            }
          </Box>
        ))
      }
    </>
  );

  const factSourcesSection = (
    <>
      <CategoryHeader
        mb={16}
        tooltipText={FACTS_TOOLTIP_TEXT}
        headerText='Facts'
      />
      {
        groupedSources.memoryItems.map((memoryItem) => (
          <Box key={memoryItem.text} mb={16}>
            <Text fz='sm'>{`- ${memoryItem.text}`}</Text>
          </Box>
        ))
      }
    </>
  );

  const proposalContextSourcesSection = (
    <>
      <CategoryHeader
        mb={16}
        tooltipText={PROPOSAL_CONTEXT_TOOLTIP_TEXT}
        headerText='Win Themes / Differentiators'
      />
      {
        groupedSources.proposalContextSources.map((proposalContextSource) => (
          <Box key={proposalContextSource} mb={16}>
            <Text fz='sm'>{`- ${proposalContextSource}`}</Text>
          </Box>
        ))
      }
    </>
  );

  const hasFactSources = groupedSources.memoryItems.length > 0;
  const hasDocumentSources = Object.keys(groupedSources.organizationResources).length > 0;
  const hasProposalContextSources = groupedSources.proposalContextSources.length > 0;
  const hasSources = hasFactSources || hasDocumentSources || hasProposalContextSources;

  const sourcesEmptyState = (
    <Center pt={32}>
      <Text>No sources found</Text>
    </Center>
  );

  return (
    <Box pr={16} pl={16}>
      <Text fw={500} fz='lg'>Sources List</Text>
      {showLastFactCheckTime &&
        <Text fz='sm' mb={16} c='var(--mantine-color-gray-6)'>
          {`Last checked: ${lastFactCheckTimeString}`}
        </Text>
      }
      {!hasSources && sourcesEmptyState}
      {hasDocumentSources && documentSourcesSection}
      {hasFactSources && factSourcesSection}
      {hasProposalContextSources && proposalContextSourcesSection}
    </Box>
  );
};

export default SourcesView;
