/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
import type { SVGProps } from "react";
const ChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill={props.color ?? "white"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.41 0.580017L6 5.17002L10.59 0.580017L12 2.00002L6 8.00002L0 2.00002L1.41 0.580017Z" />
  </svg>

);
export default ChevronDown;
