/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
import type { SVGProps } from "react";
const Copy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#868E96"
      d="M15.833 17.5H6.667V5.833h9.166m0-1.666H6.667A1.667 1.667 0 0 0 5 5.833V17.5a1.666 1.666 0 0 0 1.667 1.667h9.166A1.667 1.667 0 0 0 17.5 17.5V5.833a1.667 1.667 0 0 0-1.667-1.666m-2.5-3.334h-10A1.667 1.667 0 0 0 1.667 2.5v11.667h1.666V2.5h10z"
    />
  </svg>
);
export default Copy;
