import { Box } from '@mantine/core';

import { EditorPaneViewSelector, ResourceAssistantChat, type ViewSelectorProps } from '../../../components';

type Props = ViewSelectorProps;

const AssistantView: React.FC<Props> = (props: Props) => {
  return (
    <Box>
      <EditorPaneViewSelector {...props} />
      <ResourceAssistantChat
        style={{
          height: 'calc(100vh - 235px)'
        }}
        shouldFilterByProposal={true}
      />
    </Box>
  );
};

export default AssistantView;
