import { useCallback, useEffect } from 'react';
import {
  Flex, Text, Button, Group, ScrollArea, Box
} from '@mantine/core';
import { useParams } from 'react-router-dom';

import ModalContent from '../../ModalContent';
import { type RequirementResponse } from '../../../types/apiTypes';
import GeneratedResponseCard from '../../GeneratedResponseCard';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import CenteredLoader from '../../CenteredLoader';
import SourcesView from '../../SourcesView';

interface Props {
  onClose: () => void
  requirementResponse: RequirementResponse
}

const ResponseSources: React.FC<Props> = (props: Props) => {
  const { onClose, requirementResponse } = props;
  const { source_analysis: sourceAnalysis } = requirementResponse;

  const { proposalUid, sectionUid } = useParams();

  const runFactCheckMutation = useEnsisMutation(
    `/app/requirement-responses/${requirementResponse.uid}/source-analysis`,
    {
      requestType: 'post',
      showSuccessMessage: false,
      queryKeysToInvalidate: [
        `/app/proposals/${proposalUid}/sections/${sectionUid}/requirement-responses`
      ]
    }
  );

  const handleRerunFactCheck = useCallback(() => {
    runFactCheckMutation.mutate({});
  }, [runFactCheckMutation.mutate]);

  useEffect(() => {
    const sourceAnalysisIsNull = sourceAnalysis === undefined || sourceAnalysis == null;
    if (!runFactCheckMutation.isPending && sourceAnalysisIsNull) {
      handleRerunFactCheck();
    }
  }, []);

  const lastFactCheckTime = new Date(sourceAnalysis?.created_at ?? '');
  const lastResponseUpdateTime = new Date(requirementResponse?.updated_at ?? '');
  // Build in a little bit of buffer (1s) in case updates happen simultaneously
  const sourceAnalysisIsOutdated = (lastResponseUpdateTime.getTime() - lastFactCheckTime.getTime()) > 1000;

  const outdatedFactCheckBanner = (
    <Group p='8 16 8 16' mb={16} bg='var(--mantine-color-red-1)' justify='space-between'>
      <Text fz='sm'>Response has been modified. Would you like to rescan?</Text>
      <Button
        variant='subtle'
        color='red'
        onClick={handleRerunFactCheck}
      >
        Rescan
      </Button>
    </Group>
  );

  const loader = (
    <CenteredLoader flex={1} />
  );

  const sourcesSide = (
    <ScrollArea.Autosize flex={1} type='auto'>
      <Box pr={16} pl={16}>
        {
          sourceAnalysisIsOutdated && outdatedFactCheckBanner
        }
        <SourcesView
          showLastFactCheckTime
          sourceAnalysis={sourceAnalysis}
        />
      </Box>
    </ScrollArea.Autosize>
  );

  return (
    <ModalContent
      onClose={onClose}
      title='Source Verification'
      primaryButton={{
        label: 'Done',
        onClick: onClose
      }}
    >
      <Flex direction='row' align='stretch' mah='60vh'>
        <ScrollArea.Autosize flex={1} style={{ display: 'flex' }}>
          <GeneratedResponseCard
            requirementResponse={requirementResponse}
          />
        </ScrollArea.Autosize>
        {runFactCheckMutation.isPending ? loader : sourcesSide}
      </Flex>
    </ModalContent>
  );
};

export default ResponseSources;
