import { ActionIcon, Affix, Dialog } from '@mantine/core';

import { useDisclosure } from '@mantine/hooks';

import { useCallback } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { ChevronDown, ResourceAssistantAvatarOutline } from '../../icons';
import ResourceAssistantDialogContent from './ResourceAssistantDialogContent';

const ResourceAssistantAffix: React.FC = () => {
  const [resourceAssistantOpened, resourceAssistantHandlers] = useDisclosure();

  const location = useLocation();
  const { proposalUid } = useParams();

  const toggleResourceAssistant = useCallback(() => {
    if (resourceAssistantOpened) {
      resourceAssistantHandlers.close();
    } else {
      resourceAssistantHandlers.open();
    }
  }, [resourceAssistantOpened]);

  const hideAssistant = proposalUid !== undefined || location.pathname.includes('create-proposal');

  if (hideAssistant) {
    return <></>;
  }
  return (
    <>
      <Affix
        position={{ bottom: 20, right: 20 }}
        style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 24 }}
      >
        <ActionIcon size='xl' onClick={toggleResourceAssistant}>
          {resourceAssistantOpened ? <ChevronDown /> : <ResourceAssistantAvatarOutline />}
        </ActionIcon>
      </Affix >
      <Dialog
        position={{ bottom: 75, right: 20 }}
        p={0}
        w='39vw'
        opened={resourceAssistantOpened}
      >
        <ResourceAssistantDialogContent
          onClose={resourceAssistantHandlers.close}
        />
      </Dialog>
    </>
  );
};

export default ResourceAssistantAffix;
