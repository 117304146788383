import { Text } from '@mantine/core';

import { type ConversationMessage, type WithoutPermissions } from '../../types/apiTypes';

interface Props {
  message: WithoutPermissions<ConversationMessage>
}

const HumanChatMessage: React.FC<Props> = (props: Props) => {
  const { message } = props;
  return (
    <Text
      mt={8}
      style={{
        justifySelf: 'end',
        backgroundColor: 'var(--mantine-color-lightPurple-0)',
        borderRadius: '8px 0px 8px 8px'
      }}
      p={8}
      mr={8}
      maw='80%'
      fz='sm'
    >
      {message.content}
    </Text>
  );
};

export default HumanChatMessage;
