import { useClipboard } from '@mantine/hooks';

import { ActionIcon, Box, Button, Group, Loader, Stack, Tooltip } from '@mantine/core';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { Copy, ResourceAssistantAvatar, ShowSources } from '../../icons';
import { type ConversationMessage, type WithoutPermissions } from '../../types/apiTypes';
import SourcesView from '../SourcesView';

interface Props {
  message: WithoutPermissions<ConversationMessage>
  handleRetryMessage?: () => void
}

/* eslint-disable-next-line max-len */
const FAILURE_COPY = 'Something went wrong, and I couldn’t process your request. Please retry. If the issue persists, please contact Ensis for support.';

const AssistantChatMessage: React.FC<Props> = ({ message, handleRetryMessage }: Props) => {
  const clipboard = useClipboard();
  const [showSources, setShowSources] = useState(false);
  const handleCopyMessage = useCallback(() => {
    clipboard.copy(message.content);
  }, [clipboard, message.content]);

  const sourceRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showSources) {
      sourceRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [showSources]);

  const sources = (
    <Box
      ref={sourceRef}
      bg='var(--mantine-color-gray-0)'
      mih={150}
      p={16}
      style={{ borderRadius: '8px' }}
    >
      <SourcesView
        showLastFactCheckTime={false}
        sourceAnalysis={message.source_analysis} />
    </Box>
  );

  const messageFailed = message.status === 'failed';
  if (message.status === 'thinking') {
    return (
      <Group gap={14} pl={12} align="top" mt={8}>
        <Box mt={8}>
          <ResourceAssistantAvatar width={18} height={18} />
        </Box>
        <Loader type="dots" />
      </Group>
    );
  }

  return (
    <>
      <Group gap={14} align="top" pl={12} w='95%'>
        <Box mt={12}>
          <ResourceAssistantAvatar width={18} height={18} />
        </Box>
        <Stack gap='0px' w='calc(100% - 100px)'>
          <div style={{ fontSize: '14px', margin: '0px', padding: '0px' }}>
            <ReactMarkdown
              components={{
                p: ({ children }) => <Box mt={12}>{children}</Box>,
                ol: ({ children }) => <ol style={{ margin: 0 }}> {children} </ol>
              }}
            >
              {messageFailed ? FAILURE_COPY : message.content}
            </ReactMarkdown>
          </div>
          {messageFailed
            ? handleRetryMessage !== undefined && (
              <Group p={0} m={0}>
                <Button
                  variant='subtle'
                  onClick={handleRetryMessage}
                  size='compact-sm'
                  style={{
                    borderRadius: '4px'
                  }}
                >
                  Retry
                </Button>
              </Group>)
            : (
              <Group gap={0}>
                <Tooltip
                  label={clipboard.copied ? 'Copied' : 'Copy'}
                  fz='xs'
                >
                  <ActionIcon variant='subtle' onClick={handleCopyMessage}>
                    <Copy />
                  </ActionIcon>
                </Tooltip>
                <Tooltip
                  label={showSources ? 'Hide sources' : 'Show sources'}
                  fz='xs'
                >
                  <ActionIcon onClick={() => { setShowSources(!showSources); }} variant='subtle'>
                    <ShowSources height={20} width={20} color='var(--mantine-color-gray-4)' />
                  </ActionIcon>
                </Tooltip>
              </Group>
              )}
          {showSources && sources}
        </Stack>
      </Group>
    </>
  );
};

export default AssistantChatMessage;
