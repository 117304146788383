/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
import type { SVGProps } from "react";
const SvgResourceAssistantAvatarOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="#fff"
      d="M16 0H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2m0 16H2V2h14zm-7-1 1.88-4.12L15 9l-4.12-1.88L9 3 7.12 7.12 3 9l4.12 1.88z"
    />
  </svg>
);
export default SvgResourceAssistantAvatarOutline;
