import type { Proposal, ProposalSection, RequirementResponse, WithoutPermissions } from '../../../types/apiTypes';

import NarrativeEditor from './NarrativeEditor';
import OutlineEditor from './OutlineEditor';
import DocumentsViewer from './DocumentsViewer';
import ComplianceView from './ComplianceView';
import { type EditorViewType, type ViewSelectorProps } from '../../../components';
import AssistantView from './AssistantView';

export type NarrativeOverlayState = 'readyScreen' | null;

type EditorViewProps = {
  section: WithoutPermissions<ProposalSection>
  proposal: Proposal
  requirementResponses: RequirementResponse[]
  canEdit: boolean
  narrativeOverlayState?: NarrativeOverlayState
  setNarrativeOverlayState: (state: NarrativeOverlayState) => void
  onClickGenerateNarrative: () => void
  onComplianceModeClick: () => void
  complianceCheckIsRunning: boolean
  focusedRequirementUid: string
  onSelectRequirement?: (reqUid: string) => void
  onAddRequirement?: (requirementText: string, opportunityFileUid?: string) => void
} & ViewSelectorProps;

const viewTypeToComponentMap: Record<EditorViewType, React.FC<EditorViewProps>> = {
  outline: OutlineEditor,
  narrative: NarrativeEditor,
  documents: DocumentsViewer,
  compliance: ComplianceView,
  assistant: AssistantView
};

export const getViewTypeHasUnsavedChanges = (
  viewType: EditorViewType | null,
  outlineIsDirty: boolean,
  narrativeIsDirty: boolean
) => {
  if (viewType === 'outline') {
    return outlineIsDirty;
  } else if (viewType === 'narrative') {
    return narrativeIsDirty;
  } else return false;
};

const EditorPane: React.FC<EditorViewProps> = (props: EditorViewProps) => {
  const {
    currentViewType
  } = props;

  const ViewComponent = viewTypeToComponentMap[currentViewType ?? 'outline'];

  return <ViewComponent {...props} />;
};

export default EditorPane;
