import { type AxiosError } from 'axios';

import type { paths } from '../generated/schema';
import { API_URL } from '../Config';

import type {
  Content, PermissionType, ObjectWithPermissions, WithoutPermissions, ProposalSection
} from '../types/apiTypes';
import { showFailureNotification, showWarningNotification } from './mantineUtils';
import { type SectionData } from '../Pages/Editor/SectionEditor';

export const getAuthHeaders = (accessToken: string) => ({
  Authorization: `Bearer ${accessToken}`
});

export const getContentHeaders = (contentType: Content) => ({
  'Content-Type': contentType
});

export const getFullURLForRoute = (route: keyof paths) => (
  `${API_URL}${route}`
);

export function hasPermissionsForObject<Item extends ObjectWithPermissions> (
  obj: Item | undefined,
  permission: PermissionType
): boolean {
  return obj?.permissions?.includes(permission) ?? false;
}

export const formatSectionsMetadata: (
  data: Array<WithoutPermissions<ProposalSection>> | undefined
) => SectionData[] = (
  data: Array<WithoutPermissions<ProposalSection>> | undefined
) => data?.map((section) => ({
  uid: section.uid,
  title: section.title,
  slug: section.slug,
  ordinal: section.ordinal,
  parentSectionUid: section.parent_proposal_section_uid ?? undefined,
  childSections: (section.child_sections !== undefined && section.child_sections?.length !== 0)
    ? formatSectionsMetadata(section.child_sections ?? [])
    : []
})) ?? [];

export const handlePotentialLockError = (error: AxiosError, warningCopy: string) => {
  if (error.response?.status === 409) {
    showWarningNotification(warningCopy);
  } else {
    showFailureNotification();
  }
};

export const INVALID_FILE_ERROR_MESSAGE = 'We encountered a problem with one or more of your opportunity files. ' +
  'Please review and correct the files.';
