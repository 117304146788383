import { useEffect } from 'react';
import { Outlet, useParams, useNavigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import './App.css';
import { CenteredLoader, ResourceAssistantAffix, SideMenu } from './components';
import useEnsisQuery from './hooks/useEnsisQuery';
import NotFoundPage from './Pages/NotFoundPage';

const OrganizationApp: React.FC = () => {
  const { organization } = useParams();
  const { pathname } = useLocation();
  const { isLoading, isError } = useEnsisQuery(`/app/organizations/${organization}/data`);
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.gtag('set', 'user_properties', {
      organization
    });
  }, [organization]);

  useEffect(() => {
    if (pathname === `/org/${organization}` ||
      pathname === `/org/${organization}/`) {
      navigate('proposals');
    };
  }, [location.pathname]);

  if (isLoading) {
    return <CenteredLoader />;
  }

  if (isError) {
    return <NotFoundPage />;
  }

  return (
    <>
      <SideMenu />
      <ResourceAssistantAffix />
      <Outlet />
    </>
  );
};

export default OrganizationApp;
