import { ActionIcon, Box, Group, Text } from '@mantine/core';

import ResourceAssistantChat from './ResourceAssistantChat';
import { ChevronDown } from '../../icons';

interface Props {
  onClose: () => void
}

const ResourceAssistantDialogContent: React.FC<Props> = (props: Props) => {
  const { onClose } = props;
  return (
    <Box h={'84vh'} style={{ borderRadius: '8px' }}>
      <Group
        h={50}
        justify='space-between'
        bg='var(--mantine-color-greyPurple-0)'
        style={{
          padding: '0px 16px',
          borderRadius: '8px 8px 0px 0px',
          border: '1px solid var(--mantine-color-gray-2)'
        }}
      >
        <Text fw={500}>
          Resource Assistant
        </Text>
        <ActionIcon variant='subtle' onClick={onClose}>
          < ChevronDown color="#343A40" />
        </ActionIcon>
      </Group>
      <ResourceAssistantChat
        shouldFilterByProposal={false}
        style={{
          height: 'calc(100% - 50px)'
        }}
      />
    </Box >);
};

export default ResourceAssistantDialogContent;
