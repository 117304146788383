import { Box, ScrollArea, ActionIcon, Divider, type MantineStyleProp, Textarea } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';

import useEnsisMutation from '../../hooks/useEnsisMutation';
import useEnsisQuery from '../../hooks/useEnsisQuery';
import { Send } from '../../icons';
import AssistantChatMessage from './AssistantChatMessage';
import HumanChatMessage from './HumanChatMessage';
import { type ConversationMessage, type WithoutPermissions } from '../../types/apiTypes';

interface Props {
  shouldFilterByProposal: boolean
  style?: MantineStyleProp
}

const PROPOSAL_PAGE_PLACEHOLDER_TEXT = 'Ask about the resources selected for your proposal...';
const RESOURCES_PAGE_PLACEHOLDER_TEXT = 'Ask about the resources available for this organization...';

const ResourceAssistantChat: React.FC<Props> = (props: Props) => {
  const { shouldFilterByProposal, style } = props;
  const { organization, proposalUid } = useParams();

  const [refetchingEnabled, setRefetchingEnabled] = useState(true);
  const [userMessage, setUserMessage] = useState('');

  const createConversationMutation = useEnsisMutation('/app/conversations', {
    requestType: 'post',
    showSuccessMessage: false,
    queryKeysToInvalidate: ['/app/conversations']
  });

  const { data } = useEnsisQuery('/app/conversations', {
    queryParams: {
      organization_slug: organization ?? '',
      proposal_uid: proposalUid
    },
    enabled: shouldFilterByProposal ? proposalUid !== undefined : true,
    refetchInterval: refetchingEnabled ? 4000 : undefined,
    onSuccess: (data) => {
      if (data === null && !createConversationMutation.isPending) {
        createConversationMutation.mutate({
          organization_slug: organization ?? '',
          proposal_uid: proposalUid
        });
      }
    }
  });

  const messages = data?.messages ?? [];
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const placeholderText = shouldFilterByProposal ? PROPOSAL_PAGE_PLACEHOLDER_TEXT : RESOURCES_PAGE_PLACEHOLDER_TEXT;
  // Scroll to the bottom whenever a new message is sent
  useEffect(() => {
    const scrollArea = scrollAreaRef.current;
    if (scrollArea !== null) {
      scrollArea.scrollTo({
        top: scrollArea.scrollHeight
      });
    }
  }, [messages]);

  const numberOfMessages = messages.length;
  const isThinking = messages.length > 0 && messages[numberOfMessages - 1].status === 'thinking';

  useEffect(() => {
    setRefetchingEnabled(isThinking);
  }, [data]);
  const conversationUid = data?.uid ?? '';

  const sendMessageMutation = useEnsisMutation(`/app/conversations/${conversationUid}/messages`, {
    queryKeysToInvalidate: ['/app/conversations'],
    showSuccessMessage: false
  });

  const retryMessageMutation = useEnsisMutation(`/app/conversations/${conversationUid}/messages`, {
    requestType: 'patch',
    queryKeysToInvalidate: ['/app/conversations'],
    showSuccessMessage: false
  });

  const handleRetryMessage = useCallback(
    (aiMessageUid: string, userMessageUid: string) => {
      retryMessageMutation.mutate({
        user_message_uid: userMessageUid,
        ai_message_uid: aiMessageUid
      });
    }, [retryMessageMutation]
  );

  const canSendMessage = (
    userMessage.trim() !== '' &&
    !sendMessageMutation.isPending &&
    !isThinking &&
    !retryMessageMutation.isPending
  );
  const handleSendMessage = useCallback(() => {
    if (canSendMessage) {
      sendMessageMutation.mutate({
        content: userMessage
      }, {
        onSuccess: () => {
          setUserMessage('');
        }
      });
    }
  }, [userMessage, sendMessageMutation, conversationUid]);

  const onKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSendMessage();
    }
  }, [handleSendMessage]);

  const sendArrow = (
    <ActionIcon variant='subtle' disabled={!canSendMessage} onClick={handleSendMessage}>
      <Send />
    </ActionIcon>
  );

  const todayDivider = <Divider label='Today' p={8} />;

  const firstMessageOfTodayIndex = messages.findIndex(
    (message: WithoutPermissions<ConversationMessage>) => {
      const todaysDate = new Date().toLocaleDateString();
      const messageDate = new Date(message?.created_at ?? '').toLocaleDateString();
      return messageDate === todaysDate;
    }
  );

  const getMessageComponent = (
    message: WithoutPermissions<ConversationMessage>,
    index: number
  ) => {
    const showTodayDivider = index === firstMessageOfTodayIndex;
    const showRetryButton = index === messages.length - 1 && message.status === 'failed';
    if (message.sender === 'ai') {
      return <div key={message.uid}>
        {showTodayDivider && todayDivider}
        <AssistantChatMessage
          message={message}
          handleRetryMessage={showRetryButton
            ? () => { handleRetryMessage(message.uid ?? '', messages[index - 1].uid ?? ''); }
            : undefined} />
      </div>;
    }
    return <div key={message.uid}>
      {showTodayDivider && todayDivider}
      <HumanChatMessage message={message} />
    </div>;
  };

  return (
    <Box
      style={style}
    >
      <ScrollArea
        w='100%'
        viewportRef={scrollAreaRef}
        h='calc(100% - 72px)'
        style={{ border: '1px solid var(--mantine-color-gray-2)' }}
        pb={16}
      >
        {messages.map((message: WithoutPermissions<ConversationMessage>, index) => {
          return getMessageComponent(message, index);
        })}
      </ScrollArea>
      <Box p='8px 8px' h={72} bg="var(--mantine-color-greyPurple-0)">
        <Textarea
          rightSection={sendArrow}
          placeholder={placeholderText}
          value={userMessage}
          onKeyDown={onKeyDown}
          onChange={(e) => { setUserMessage(e.currentTarget.value); }}
          autosize
          maxRows={2}
          minRows={2}
        />
      </Box>
    </Box >);
};

export default ResourceAssistantChat;
