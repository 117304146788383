/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
import type { SVGProps } from "react";
const SvgGenerateAll = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 15 17"
    {...props}
  >
    <path
      fill="#7139B0"
      fillRule="evenodd"
      d="M9.02 2.213c-.17-.04-.36-.046-1.01-.046H6.167c-.714 0-1.2 0-1.575.031-.365.03-.552.084-.682.15-.314.16-.569.415-.728.729-.067.13-.121.317-.15.682C3 4.134 3 4.62 3 5.333V8H1.333V5.299c0-.67 0-1.224.037-1.675.038-.469.12-.9.327-1.304.32-.627.83-1.137 1.456-1.457.405-.206.835-.288 1.304-.326C4.907.5 5.46.5 6.132.5h1.963c.529 0 .929 0 1.314.092.34.082.665.216.964.4.338.206.62.49.994.864l.06.06 1.824 1.823.06.06c.374.374.657.657.864.995.183.298.318.623.4.963.092.386.092.786.092 1.315v3.629c0 .67 0 1.225-.037 1.676-.039.468-.12.899-.327 1.303a3.33 3.33 0 0 1-1.457 1.457c-.404.206-.835.288-1.303.326-.45.037-1.004.037-1.675.037H8.833v-1.667h1c.714 0 1.2 0 1.574-.03.366-.03.553-.085.683-.151.313-.16.569-.415.728-.729.067-.13.12-.317.15-.682.031-.375.032-.86.032-1.574v-3.51c0-.651-.005-.841-.046-1.01a1.7 1.7 0 0 0-.2-.482c-.09-.149-.221-.287-.682-.747L10.25 3.094c-.46-.46-.598-.59-.747-.682a1.7 1.7 0 0 0-.482-.2"
      clipRule="evenodd"
    />
    <path
      fill="#7139B0"
      fillRule="evenodd"
      d="m2.605 11.771.754-2.262h.949l.754 2.262 2.263.755v.948l-2.263.755-.754 2.262h-.95l-.753-2.262-2.263-.755v-.948zM9.667 2.167V5.5H13v1.667H8.833A.833.833 0 0 1 8 6.333V2.167z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGenerateAll;
